import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../components/logo'
import styled from "styled-components"

const HeaderContainer = styled.div`
background: #222;
padding:1em 1.5em!important;
display:flex;
align-items:center;
justify-content:space-between;

a {
  line-height:0;
}
`
const Menu = styled.div
`
color:white;
`



const Header = ({ siteTitle }) => (
  <HeaderContainer className="container-fluid">
         <div style={{'width':'100px'}}><Link
          to="/"
          title={siteTitle}
          style={{
            color: `white`,
            textDecoration: `none`,
          }}><Logo/></Link></div>
  
    <Menu>
      menu
    </Menu>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
